import './app.css';
import './assets/scss/main.scss';

import React, { useState, lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GlobalContext } from './context';
import { AuthRoute, ProtectedRoute, PublicRoute, RoutesEnum } from './helpers';
import { LazyLoadWrapper } from './components';

// Lazy load the components and wrap them with LazyLoadWrapper
const About = LazyLoadWrapper(lazy(() => import('./pages/About')));
const Clubs = LazyLoadWrapper(lazy(() => import('./pages/Clubs')));
const Events = LazyLoadWrapper(lazy(() => import('./pages/Events')));
const Home = LazyLoadWrapper(lazy(() => import('./pages/Home')));
const Login = LazyLoadWrapper(lazy(() => import('./pages/Login')));
const Newsletters = LazyLoadWrapper(lazy(() => import('./pages/Newsletters')));
const PageNotFound = LazyLoadWrapper(
  lazy(() => import('./pages/PageNotFound')),
);
const Sports = LazyLoadWrapper(lazy(() => import('./pages/Sports')));
const IndividualClub = LazyLoadWrapper(
  lazy(() => import('./pages/IndividualClub')),
);

const App = () => {
  // States.
  const [loggedInUser, setLoggedInUser] = useState(null);

  // Renders.
  return (
    <div className="app">
      <GlobalContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <BrowserRouter>
          <Routes>
            {/* Navigate to Clubs page by default */}
            <Route path="/" element={<Navigate to={RoutesEnum.Clubs} />} />

            {/* Main Routes */}
            <Route exact path={RoutesEnum.Home} element={<Home />}>
              {/* Auth Routes */}
              <Route element={<AuthRoute />}>
                <Route path={RoutesEnum.Login} element={<Login />} />
              </Route>

              <Route element={<PublicRoute />}>
                {/* All Public Routes go here. */}
                <Route path={RoutesEnum.Events} element={<Events />} />
                <Route
                  path={RoutesEnum.IndividualClub}
                  element={<IndividualClub />}
                />
                <Route path={RoutesEnum.Clubs} element={<Clubs />} />
                <Route path={RoutesEnum.Sports} element={<Sports />} />
                <Route path={RoutesEnum.About} element={<About />} />
                <Route
                  path={RoutesEnum.Newsletters}
                  element={<Newsletters />}
                />
              </Route>

              <Route element={<ProtectedRoute />}>
                {/* All Protected Routes go here. */}
              </Route>
            </Route>

            <Route
              path="*"
              element={<Navigate to={RoutesEnum.PageNotFound} />}
            />
            <Route path={RoutesEnum.PageNotFound} element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </GlobalContext.Provider>
    </div>
  );
};

export default App;
