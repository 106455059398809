import PropTypes from 'prop-types';
import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Icon, IconIdentifier } from '../../atoms';
import { Colors } from '../../helpers';
import './Modal.scss';

const Modal = ({
  onHide,
  className,
  contentClassName,
  children,
  centered = true,
  ...rest
}) => {
  const classNames = ['sag-modal', className].join(' ');
  const contentClassNames = ['sag-modal__content', contentClassName].join(' ');

  return (
    <BootstrapModal
      centered={centered}
      onHide={onHide}
      className={classNames}
      contentClassName={contentClassNames}
      {...rest}
    >
      {children}
    </BootstrapModal>
  );
};

Modal.propTypes = {
  ...BootstrapModal.propTypes,
};

Modal.Header = ({
  className,
  children,
  showCloseButton = false,
  onClose = () => {},
}) => {
  const classNames = ['sag-modal__header', className].join(' ');

  return (
    <BootstrapModal.Header className={classNames}>
      {children}
      {showCloseButton && (
        <Icon
          className="sag-modal__header__button--close"
          iconIdentifier={IconIdentifier.Cross}
          iconSize={'2rem'}
          color={Colors.Secondary}
          onClick={onClose}
        />
      )}
    </BootstrapModal.Header>
  );
};

Modal.Header.propTypes = {
  ...BootstrapModal.Header.propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.Header.displayName = 'Modal.Header';

Modal.Body = ({ className, children }) => {
  const classNames = ['sag-modal__body', className].join(' ');

  return (
    <BootstrapModal.Body className={classNames}>{children}</BootstrapModal.Body>
  );
};

Modal.Body.propTypes = {
  ...BootstrapModal.Body.propTypes,
  className: PropTypes.string,
  children: PropTypes.node,
};

Modal.Body.displayName = 'Modal.Body';

export default Modal;
