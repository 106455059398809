import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const FormControl = ({ children, className, ...rest }) => {
  const classNames = ['sag-form-control', className].join(' ');
  return (
    <Form.Control className={classNames} {...rest}>
      {children}
    </Form.Control>
  );
};

FormControl.propTypes = {
  ...Form.Control.propTypes,
  children: PropTypes.node,
  className: PropTypes.string,
};

FormControl.Date = React.forwardRef(({ className, ...rest }, ref) => {
  const classNames = ['sag-form-control__date', className];
  return (
    <Form.Control type="date" className={classNames} {...rest} ref={ref} />
  );
});

FormControl.Date.propTypes = {
  ...Form.Control.propTypes,
  className: PropTypes.string,
};

FormControl.Date.displayName = 'FormControl.Date';

FormControl.DateTime = React.forwardRef(({ className, ...rest }, ref) => {
  const classNames = ['sag-form-control__datetime', className];
  return (
    <Form.Control
      type="datetime-local"
      className={classNames}
      {...rest}
      ref={ref}
    />
  );
});

FormControl.DateTime.propTypes = {
  ...Form.Control.propTypes,
  className: PropTypes.string,
};

FormControl.DateTime.displayName = 'FormControl.DateTime';

export default FormControl;
