import ArtAndCraftClubLogo from '../../../assets/images/club-logos/art-n-craft-club-logo.png';
import CulturalClubLogo from '../../../assets/images/club-logos/cultural-club-logo.png';
import DisciplineCommitteeLogo from '../../../assets/images/club-logos/discipline-committee-logo.png';
import HumanValuesClubLogo from '../../../assets/images/club-logos/human-values-club-logo.png';
import JournalismClubLogo from '../../../assets/images/club-logos/journalism-club-logo.png';
import LiteralClubLogo from '../../../assets/images/club-logos/literal-club-logo.png';
import ManagementClubLogo from '../../../assets/images/club-logos/management-club-logo.png';
import MathClubLogo from '../../../assets/images/club-logos/math-club-logo.png';
import PublicationClubLogo from '../../../assets/images/club-logos/publication-club-logo.png';
import TechBizzLogo from '../../../assets/images/club-logos/tech-bizz-logo.png';
import SagLogo from '../../../assets/images/sag-logo.png';

export const LogoIdentifier = {
  ArtAndCraftClub: ArtAndCraftClubLogo,
  CulturalClub: CulturalClubLogo,
  DisciplineCommittee: DisciplineCommitteeLogo,
  HumanValuesClub: HumanValuesClubLogo,
  JournalismClub: JournalismClubLogo,
  LiteralClub: LiteralClubLogo,
  ManagementClub: ManagementClubLogo,
  MathClub: MathClubLogo,
  PublicationClub: PublicationClubLogo,
  SAG: SagLogo,
  TechBizz: TechBizzLogo,
};
