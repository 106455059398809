import AboutSag from './about-illustration.svg';
import AboutMission from './about-mission.svg';
import AboutVision from './about-vision.svg';
import Artist from './artist.svg';
import BellCrossed from './bell-crossed.svg';
import AboutDesign from './design-team.svg';
import AboutDevelopment from './development-team.svg';
import EmployeeErrorMonitor from './employee_error_monitor.svg';
import GroupPlayingMusic from './group-playing-music.png';
import Music from './music.svg';
import NotFoundFolder from './not_found_folder.svg';
import PageNotFoundCryingMonster from './page_not_found_crying_moster.svg';
import Photography from './photography.svg';
import Science from './science.svg';
import SleepingEmployee from './sleeping_employee.svg';
import UserChilling from './user-chilling.svg';
import UserInDoubt from './user-in-doubt.svg';
import ImageNotFound from './image-not-found.svg';

export const Illustration = {
  AboutDesign,
  AboutDevelopment,
  AboutMission,
  AboutSag,
  AboutVision,
  Artist,
  BellCrossed,
  EmployeeErrorMonitor,
  GroupPlayingMusic,
  Music,
  NotFoundFolder,
  PageNotFoundCryingMonster,
  Photography,
  Science,
  SleepingEmployee,
  UserChilling,
  UserInDoubt,
  ImageNotFound,
};
