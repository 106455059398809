import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Grid = ({ children, className, itemClassName }) => {
  const gridClassNames = classNames('sag-grid', className);
  const gridItemClassNames = classNames('sag-grid__item', itemClassName);

  return (
    <div className={gridClassNames}>
      {React.Children.map(children, (child) => {
        return <div className={gridItemClassNames}>{child}</div>;
      })}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
};

export default Grid;
