import React from 'react';
import PropTypes from 'prop-types';
import { Card as BootstrapCard } from 'react-bootstrap';
import classNames from 'classnames';

const Card = ({ cardHeader, children, className }) => {
  return (
    <BootstrapCard className={classNames('sag-card', className)}>
      <BootstrapCard.Header className="sag-card__header">
        {cardHeader}
      </BootstrapCard.Header>
      <BootstrapCard.Body className="sag-card__body">
        {children}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

Card.propTypes = {
  cardHeader: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
