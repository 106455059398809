export const isHexColorCode = (props, propName, comoponentName) => {
  const regexToMatchHexCode = /^#[A-F | a-f | 0-9]{6}/g;

  if (!!props[propName] && !regexToMatchHexCode.test(props[propName])) {
    return new Error(
      `Invalid proper ${propName} passed to ${comoponentName}. Expected a color hex code.`,
    );
  }
};

export const Colors = {
  // Theme Colors.
  Primary: '#0C3B5B',
  PrimaryLight: '#2A648B',
  Secondary: '#DCDCDC',
  MainBackground: '#EDEDED',

  // Blacks.
  Black50: '#F5F5F5',
  Black100: '#ECECEC',
  Black150: '#EBEBEB',
  Black200: '#DFDFDF',
  Black300: '#C9C9C9',
  Black400: '#A7A7A7',
  Black500: '#929292',
  Black600: '#7C7C7C',
  Black700: '#5F5F5F',
  Black800: '#383838',
  Black900: '#2D2D2D',
  Black: '#000000',

  // Whites.
  White: '#FFFFFF',

  // Customs.
  HyperlinkBlue: '#729AE8',
  LogoutRed: '#DC3545',
};
