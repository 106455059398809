import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ButtonVariant, IconIdentifier } from '../../atoms';

const CloudinaryUpload = ({ onUploadSuccess }) => {
  const [cloudinaryLoaded, setCloudinaryLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (window.cloudinary && window.cloudinary.createUploadWidget) {
      setCloudinaryLoaded(true);
    } else {
      // Dynamic Loading
      const script = document.createElement('script');
      script.src = 'https://widget.cloudinary.com/v2.0/global/all.js';
      script.async = true;
      script.onload = () => setCloudinaryLoaded(true);
      document.body.appendChild(script);
    }
  }, []);

  const handleUpload = () => {
    if (cloudinaryLoaded) {
      const myWidget = window.cloudinary.createUploadWidget(
        {
          cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
          uploadPreset: import.meta.env.VITE_CLOUDINARY_CLOUD_PRESET,
          sources: ['local', 'url'],
          showAdvancedOptions: true,
          cropping: false,
          multiple: false,
          defaultSource: 'local',
          folder: import.meta.env.VITE_CLOUDINARY_UPLOAD_FOLDER,
          styles: {
            palette: {
              window: '#FFFFFF',
              windowBorder: '#0C3B5B',
              tabIcon: '#0032FF',
              menuIcons: '#5A616A',
              textDark: '#0C3B5B',
              textLight: '#FFFFFF',
              link: '#0C3B5B',
              action: '#FF620C',
              inactiveTabIcon: '#0E2F5A',
              error: '#F44235',
              inProgress: '#0078FF',
              complete: '#20B832',
              sourceBg: '#DCDCDC',
            },
          },
        },
        (error, result) => {
          if (error) {
            console.error('Error uploading image:', error);
            toast.error('Error occurred. Please try again.');
          } else if (result && result.event === 'success') {
            const imageUrl = result.info.secure_url;
            setImageUrl(imageUrl);
            toast.success('Image uploaded successfully');
            onUploadSuccess(imageUrl);
          }
        },
      );
      myWidget.open();
    }
  };

  return (
    <div className="cloudinary-upload-widget">
      <div className="cloudinary-upload-widget__preview-container">
        {imageUrl && (
          <img
            className="cloudinary-upload-widget__preview"
            src={imageUrl}
            alt="Preview"
          />
        )}

        <Button
          leftIconIdentifier={IconIdentifier.CloudUp}
          className="mb-3 cloudinary-upload-widget-button"
          variant={imageUrl ? ButtonVariant.Secondary : ButtonVariant.Primary}
          onClick={handleUpload}
        >
          {imageUrl ? 'Change Image' : 'Upload'}
        </Button>
      </div>
    </div>
  );
};

CloudinaryUpload.propTypes = {
  onUploadSuccess: PropTypes.func.isRequired,
};

export default CloudinaryUpload;
