import React from 'react';
import PropTypes from 'prop-types';

// Preserves aspect ratio by controling height.
const ImagePlaceholder = ({ heightToWidthRatio = 1, children = null }) => {
  // Conditional Constants.
  const wrapperStyle = { paddingBottom: `${heightToWidthRatio * 100}%` };

  return (
    // Ref: https://stackoverflow.com/a/10441480.
    <div className="sag-image-placeholder__wrapper" style={wrapperStyle}>
      <div className="sag-image-placeholder__container">{children}</div>
    </div>
  );
};

ImagePlaceholder.propTypes = {
  heightToWidthRatio: PropTypes.number,
  children: PropTypes.node,
};

export default ImagePlaceholder;
