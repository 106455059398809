import React from 'react';
import PropTypes from 'prop-types';
import { Colors, Icon, IconIdentifier } from '../..';

const NoteCard = ({ noteHeading, children }) => {
  return (
    <div className="sag-note-card">
      <div className="sag-note-card__heading-container">
        <Icon
          iconIdentifier={IconIdentifier.Error}
          color={Colors.White}
          className="sag-note-card__heading-icon"
        />
        <span className="sag-note-card__heading-text">{noteHeading}</span>
      </div>
      <div className="sag-note-card__body--text"> {children}</div>
    </div>
  );
};

NoteCard.propTypes = {
  noteHeading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default NoteCard;
