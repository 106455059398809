import React from 'react';
import ReactDOM from 'react-dom/client';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { QueryClientProvider } from 'react-query';

// Add bootstrap to the project
import '../node_modules/bootstrap/dist/css/bootstrap.css';
// Add react-toastify
import 'react-toastify/dist/ReactToastify.css';

import './index.css';

import App from './app';
import { queryClient } from './api';
import { ToastManager, TopLoadingBar } from './components';
import { SkeletonTheme } from 'react-loading-skeleton';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <SkeletonTheme color="#202020">
      <ToastManager />
      <TopLoadingBar />
      <QueryClientProvider client={queryClient}>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
      </QueryClientProvider>
    </SkeletonTheme>
  </>,
);
