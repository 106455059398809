import { PropTypes } from 'prop-types';
import React from 'react';
import { Badge as BootstrapBadge } from 'react-bootstrap';

import { Icon, IconIdentifier } from '../../atoms';
import { Colors, isHexColorCode } from '../../helpers';
import './Badge.css';

const Badge = ({ text, iconIdentifier, color = Colors.Primary }) => {
  return (
    <BootstrapBadge
      className="sag-badge"
      bg="sag-color"
      style={{ backgroundColor: color }}
    >
      <Icon
        className="sag-icon"
        iconIdentifier={iconIdentifier}
        color={Colors.White}
        iconSize="10"
      />
      {text}
    </BootstrapBadge>
  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  iconIdentifier: PropTypes.oneOf(Object.values(IconIdentifier)),
  color: isHexColorCode,
};

export default Badge;
