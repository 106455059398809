export const IconIdentifier = {
  Bell: 'bell',
  BellOff: 'bell-off',
  Bulb: 'bulb',
  Calendar: 'calendar',
  Call: 'call',
  Check: 'check-icon',
  ChevronDown: 'chevron-down',
  ChevronDownThin: 'chevron-down-thin',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ChevronUp: 'chevron-up',
  ChevronUpThin: 'chevron-up-thin',
  ClipLink: 'clip-link',
  CloudUp: 'cloud-up',
  Cross: 'cross',
  Error: 'error',
  Eye: 'eye',
  EyeHide: 'eye-hide',
  Facebook: 'facebook',
  Filter: 'filter',
  Gallery: 'gallery',
  Github: 'github',
  Google: 'google',
  Groups: 'groups',
  Home: 'home',
  Hamburger: 'hamburger',
  Info: 'info',
  Keynote: 'keynote',
  LinkedIn: 'linkedin',
  LocationPin: 'location-pin',
  Logout: 'logout',
  Mail: 'mail',
  Newspaper: 'newspaper',
  PinFilled: 'pin-filled',
  PinOutline: 'pin-outline',
  Plus: 'plus',
  Profile: 'profile',
  RegistrationForm: 'register-form',
  Soccer: 'soccer',
  Star: 'star',
  SquareCross: 'square-cross',
  SquarePencil: 'square-pencil',
  Tools: 'tools',
  Trophy: 'trophy',
  Twitter: 'twitter',
};
