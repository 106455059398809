import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import { Icon, IconIdentifier } from '..';
import classNames from 'classnames';
import './Dropdown.scss';

export const DropdownVariant = {
  LargeDesktop: 'large-desktop',
  SmallDesktop: 'small-desktop',
  LargeMobile: 'large-mobile',
  SmallMobile: 'small-mobile',
};

const Dropdown = ({ variant, options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  const renderDropdownIcon = () => {
    const icon = isOpen ? IconIdentifier.ChevronUp : IconIdentifier.ChevronDown;
    return (
      <Icon iconIdentifier={icon} color="black" className="dropdown__icon" />
    );
  };

  const dropdownClassName = classNames('sag-dropdown', {
    [`sag-dropdown--${variant}`]: variant,
    'sag-dropdown--open': isOpen,
  });

  const toggleClassName = classNames('sag-dropdown__toggle', {
    'sag-dropdown__toggle--open': isOpen,
  });

  return (
    <BootstrapDropdown
      className={dropdownClassName}
      show={isOpen}
      onSelect={handleOptionSelect}
    >
      <BootstrapDropdown.Toggle
        className={toggleClassName}
        onClick={handleToggle}
      >
        <span className="sag-dropdown__toggle-text">{selectedOption}</span>
        <span className="sag-dropdown__toggle-icon">
          {renderDropdownIcon()}
        </span>
      </BootstrapDropdown.Toggle>

      <BootstrapDropdown.Menu className="sag-dropdown__menu">
        {options.map((option) => (
          <BootstrapDropdown.Item key={option} eventKey={option}>
            {option}
          </BootstrapDropdown.Item>
        ))}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
};

Dropdown.propTypes = {
  variant: PropTypes.oneOf(Object.values(DropdownVariant)).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func,
};

export default Dropdown;
