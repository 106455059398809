import PropTypes from 'prop-types';
import React from 'react';
import ReactLoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './Skeleton.scss';

const Skeleton = ({ className, ...props }) => {
  return (
    <ReactLoadingSkeleton className={`skeleton ${className}`} {...props} />
  );
};

Skeleton.propTypes = {
  className: PropTypes.string,
};

export default Skeleton;
