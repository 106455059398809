import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip as BootstrapTooltip, OverlayTrigger } from 'react-bootstrap';
import './Tooltip.css';

const Tooltip = ({ placement, tooltipText, children, hide }) => {
  if (hide) {
    return children;
  }

  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <BootstrapTooltip id="tooltip" className="sag-tooltip">
          {tooltipText}
        </BootstrapTooltip>
      }
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  tooltipText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
};

export default Tooltip;
