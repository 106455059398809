import React from 'react';
import Form from 'react-bootstrap/Form';
import './Checkbox.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({ label, name, checked, onChange, className }) => {
  return (
    <Form.Check className="sag-checkbox__container">
      <Form.Check.Input
        className={classNames('sag-checkbox', className)}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        id={`checkbox-${label}`}
      />
      <Form.Check.Label
        className="sag-checkbox__label"
        htmlFor={`checkbox-${label}`}
      >
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.node.isRequired,
  onChange: PropTypes.node.isRequired,
};

export default Checkbox;
