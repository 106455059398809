import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Illustration } from '../../../assets/images';

export const AdminIntroCard = ({
  name,
  designation,
  thumbnailImage = {},
  className,
}) => {
  //States.
  const [imageNotLoaded, setImageNotLoaded] = useState(false);

  // Handlers.
  const handleImageLoading = () => {
    setImageNotLoaded(true);
  };

  const customClassNames = classNames('admin-intro-card', className);

  return (
    <Card className={customClassNames}>
      <Card.Body className="admin-intro-card__body">
        {imageNotLoaded ? (
          <div>
            <img
              className="admin-intro-card__body__fallback-image"
              src={Illustration.ImageNotFound}
              alt="Fallback Image"
            />
            <span className="admin-intro-card__error-text">
              Failed to Load the Image.
            </span>
          </div>
        ) : (
          <Card.Img
            className="admin-intro-card__image"
            variant="top"
            src={thumbnailImage}
            onError={handleImageLoading}
          />
        )}
        <Card.Title className="admin-intro-card__title">{name}</Card.Title>
        <Card.Text className="admin-intro-card__designation">
          {designation}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

AdminIntroCard.propTypes = {
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  thumbnailImage: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default AdminIntroCard;
