import React, { Suspense } from 'react';
import { PageLoader } from '../../atoms';

const LazyLoadWrapper = (Component) => {
  return function WrappedComponent(props) {
    return (
      <Suspense fallback={<PageLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

export default LazyLoadWrapper;
