import React, { useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Colors } from '../../helpers';
import './TopLoadingBar.css';

export let loadingBarRef;

export const startTopLoading = () => {
  if (loadingBarRef) {
    loadingBarRef.current?.continuousStart();
  }
};

export const completeTopLoading = () => {
  if (loadingBarRef) {
    loadingBarRef.current?.complete();
  }
};

const TopLoadingBar = () => {
  loadingBarRef = useRef(null);
  return (
    <LoadingBar
      className="sag-top-loading-bar"
      color={Colors.Primary}
      ref={loadingBarRef}
    />
  );
};
TopLoadingBar.displayName = 'TopLoadingBar';

export default TopLoadingBar;
